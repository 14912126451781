@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;600;700&display=swap');

* {
  padding:0;
  margin:0;
  background-color: rgb(43, 47, 49);
  color: rgb(235, 248, 254);
}

.App {
  text-align: center;
  color:rgb(62, 64, 65);
  background-color: rgb(43, 47, 49);
  font-family: 'Jost', sans-serif;
  height:100%;
align-items: center;
}


 .App .container{
   margin-left:10%; 
   margin-right:10%; 
   align-items: center
 }


 @media (max-width:900px){
  .App .container{
    margin-left:0%; 
    margin-right:0%; 
    align-items: center
  }
 }


