.blogs {
  margin-top: 100px;
  text-align: center;
  color: rgb(62, 64, 65);
  h3 {
    margin-top: 40px;
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 10px;
    justify-content: space-evenly;

    &__item {
      flex: 0 1 45%;
      background-color: rgba(91, 99, 104, 0.178);
      border-radius: 10px;
      text-decoration: none;
      img {
        width: 90%;
        height: 120px;
        margin-top: 30px;
      }

      &__details {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        margin-top: 30px;
        background-color: rgba(49, 129, 179, 0);
        h4 {
          background-color: rgba(91, 99, 104, 0);
          padding: 30px;
        }

        &__item {
          background-color: rgba(49, 129, 179, 0);
        }

        span {
          background: rgba(91, 99, 104, 0.507);
          padding: 6px;
          margin: 6px;
          border-radius: 30px;
          font-size: 12sp;
        }
      }
    }

    &__item:hover {
      transform: scale(1.07);
      transition: 0.2s linear;
    }
  }
}

@media (max-width: 800px) {
  .blogs {
    padding: 10px;
    &__container {
      display: flex;
      align-items: center;
      flex-direction: column;
      &__item {
        width: 90%;
        margin: 20px;
      }
    }
  }
}
